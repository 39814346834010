
import { ECharts } from "echarts/lib/echarts";
import entryExitChartData from "./BackTestChartGeneral/entryExitChartData";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Position } from "next-trader-api";

const indexCandleData = {
  date: "d",
  open: "o",
  high: "h",
  low: "l",
  close: "c",
  volume: "v",
};

// let indexIndicatorData = {
//   date: 0,
//   ma10: 1,
//   ma20: 2,
//   rsi: 3
// };

// let indexPositions = {
//   date: 0,
//   targetPrice: 1,
//   stopLoss: 2
// };

@Component
export default class BackTestChartGeneral extends Vue {
  @Prop()
  positionHistory?: Array<Position>;
  // watch: {
  //   positionHistory: function() {
  //     this.calculateData();
  //   }
  // },

  @Prop()
  candleData?: any;

  @Prop()
  title?: string;

  @Prop()
  indicatorData: any;

  private ei: any | ECharts = {};

  moveToPos(from: number, to: number) {
    this.ei.dispatchAction({
      type: "dataZoom",
      startValue: from,
      endValue: to,
    });
  }

  calculateData(from?: number, to?: number) {
    const result = entryExitChartData.generate(this.positionHistory);
    this.options.series[0].markPoint.data = result.entryData;
    this.options.series[0].markArea.data = result.exitData;

    if (from && to) {
      this.options.dataZoom[0].startValue = from;
      this.options.dataZoom[0].endValue = to;
    }
  }

  mounted() {
    this.options.dataset.source = this.candleData;
    this.calculateData();
  }

  @Watch("candleData")
  onCandleDataChange() {
    this.options.dataset.source = this.candleData;
    this.options.title.text = this.title;
    this.calculateData();
  }

  private upColor = "#00da3c";
  private upBorderColor = "#008F28";
  private downColor = "#ec0000";
  private downBorderColor = "#8A0000";

  private options = {
    animation: false,
    dataset: {
      source: [],
    },
    title: {
      text: this.title,
      top: 15,
      left: 15,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false,
        },
      },
    },
    grid: [
      {
        left: "4%",
        right: "4%",
        bottom: 180,
      },
      {
        left: "4%",
        right: "4%",
        height: 80,
        bottom: 50,
      },
    ],
    xAxis: [
      {
        type: "category",
        scale: true,
        boundaryGap: false,
        // inverse: true,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
      {
        type: "category",
        gridIndex: 1,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
    ],
    yAxis: [
      {
        scale: true,
        splitArea: {
          show: true,
        },
      },
      {
        scale: true,
        splitNumber: 2,
        max: function (value) {
          return value.max * 5;
        },
      },
      {
        scale: true,
        gridIndex: 1,
      },
    ],
    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0, 1],
        startValue: 0,
        endValue: 1000,
      },
      {
        show: true,
        xAxisIndex: [0, 1],
        type: "slider",
        bottom: 10,
        start: 0,
        end: 10,
        handleIcon:
          "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
        handleSize: "105%",
      },
    ],
    series: [
      {
        type: "candlestick",
        itemStyle: {
          color: this.upColor,
          color0: this.downColor,
          borderColor: this.upBorderColor,
          borderColor0: this.downBorderColor,
        },
        encode: {
          x: indexCandleData.date,
          y: [indexCandleData.open, indexCandleData.close, indexCandleData.low, indexCandleData.high],
        },
        markPoint: {
          label: {
            normal: {
              formatter: function (param) {
                return param != null ? Math.round(param.value) : "";
              },
            },
          },
          data: [],
          tooltip: {
            formatter: function (param) {
              return param.name + "<br>" + (param.data.coord || "");
            },
          },
        },
        markArea: {
          data: [],
        },
      },
      // {
      //   name: "MA10",
      //   type: "line",
      //   symbol: "none",
      //   encode: {
      //     x: indexIndicatorData.date,
      //     y: indexIndicatorData.ma10
      //   },
      //   smooth: true
      // },
      // {
      //   name: "MA20",
      //   type: "line",
      //   symbol: "none",
      //   encode: {
      //     x: indexIndicatorData.date,
      //     y: indexIndicatorData.ma20
      //   },
      //   smooth: true
      // },
      {
        name: "Volumn",
        type: "bar",
        xAxisIndex: 0,
        yAxisIndex: 1,
        itemStyle: {
          color: "#7fbe9e",
          opacity: 0.5,
        },
        large: true,
        encode: {
          x: indexCandleData.date,
          y: indexCandleData.volume,
        },
      },
      // {
      //   name: "RSI",
      //   type: "line",
      //   symbol: "none",
      //   xAxisIndex: 1,
      //   yAxisIndex: 2,
      //   itemStyle: {
      //     color: "#7fbe9e"
      //   },
      //   large: true,
      //   encode: {
      //     x: indexIndicatorData.date,
      //     y: indexIndicatorData.rsi
      //   },
      //   markArea: {
      //     data: [
      //       [
      //         {
      //           yAxis: 30
      //         },
      //         {
      //           yAxis: 70
      //         }
      //       ]
      //     ]
      //   }
      // }
      // {
      //   name: "Exit price",
      //   type: "line",
      //   symbol: "none",
      //   lineStyle: {
      //     color: "green",
      //     width: 1,
      //     opacity: 0.5
      //   },
      //   encode: {
      //     x: indexPositions.date,
      //     y: indexPositions.targetPrice
      //   }
      // },
      // {
      //   name: "Stop loss",
      //   type: "line",
      //   symbol: "none",
      //   lineStyle: {
      //     color: "red",
      //     width: 1,
      //     opacity: 0.5
      //   },
      //   encode: {
      //     x: indexPositions.date,
      //     y: indexPositions.stopLoss
      //   }
      // }
    ],
  };
}
