
import { testApi } from "@/plugins/api";
import Component from "vue-class-component";
import Vue from "vue";
import { combineLatest } from "rxjs";
import ProfitLoss from "@/components/ProfitLoss.vue";
import PositionHistory from "@/components/PositionHistory.vue";
import PositionEnvSummary from "@/components/PositionEnvSummary.vue";
import { Filters } from "next-trader-api";

@Component({
  components: {
    ProfitLoss,
    PositionHistory,
    PositionEnvSummary,
  },
})
export default class Test extends Vue {
  tab = 0;
  responseData: any = null;
  candleChartData: any = null;
  filters: Filters = {
    markets: ["ETH/USDT"],
    dateFrom: "2019-12-01",
    dateTo: new Date().toISOString().substr(0, 10),
    groupValues: { RSI: [], MA: [] },
  };

  created() {
    this.loadData();
  }

  loadData() {
    const observable1 = testApi.postAppTestTest({
      body: {
        filters: this.filters,
      },
    });
    const observable2 = testApi.postAppTestGetcandlechartdata({
      body: {
        filters: this.filters,
      },
    });

    const combined = combineLatest([observable1, observable2]);
    combined.subscribe((next) => {
      this.responseData = next[0];
      this.candleChartData = next[1];
    });
  }
}
