
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { mdiCalendar, mdiTrendingUp } from "@mdi/js";

@Component
export default class Filters extends Vue {
  @Prop()
  groups: any;

  @Prop()
  filters: any;

  markets: Array<string> = ["ETH/USDT", "BTC/USDT"];
  menuFrom = false;
  menuTo = false;

  icons = {
    mdiCalendar: mdiCalendar,
    mdiTrendingUp: mdiTrendingUp,
  };

  created() {
    //Select all group items by default
    for (const [groupKey, item] of Object.entries(this.groups)) {
      for (const [key] of Object.entries(item)) {
        this.filters.groupValues[groupKey].push(key);
      }
    }
  }

  doFilter() {
    this.$emit("filter", this.filters);
  }
}
