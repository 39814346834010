
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import {
  mdiCalendar,
  mdiArrowTopRightThick,
  mdiArrowBottomRightThick,
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronRight,
} from "@mdi/js";
import { Position } from "next-trader-api/models";
import { DataTableHeader } from "vuetify";

@Component
export default class PositionHistory extends Vue {
  @Prop({ required: true })
  positionHistory?: Array<Position>;

  @Prop({ required: true })
  activePosition?: Position | null;

  @Prop({ required: true })
  mainCurrencyName?: string;

  @Prop({ required: true })
  isBackTesting?: boolean;

  @Prop()
  profitInPercent?: boolean;

  format1 = "0,0.[000000]";
  format2 = "0,0";
  formatPercent = "0,0.[00]";

  icons = {
    mdiCalendar: mdiCalendar,
    mdiArrowTopRightThick: mdiArrowTopRightThick,
    mdiArrowBottomRightThick: mdiArrowBottomRightThick,
    mdiChevronUp: mdiChevronUp,
    mdiChevronDown: mdiChevronDown,
    mdiChevronRight: mdiChevronRight,
  };

  getHeaders() {
    return [
      { text: "#", value: "index", width: 50, cellClass: "pl-3 pr-3", class: "pl-3 pr-3" },
      { text: "", value: "isLong", width: 32, cellClass: "px-0", class: "px-0" },
      this.isBackTesting
        ? {
            text: "Entry date",
            value: "entryDate",
          }
        : {
            text: "Created at",
            value: "createdAt",
          },
      { text: "Market", value: "market" },
      this.profitInPercent ? { text: "Profit", value: "profitPercent" } : { text: "Profit", value: "profit" },
      //{ text: "Capital after", value: "actualCapital" },
      { text: "Entry price", value: "entryPrice" },
      { text: "Close price", value: "closePrice" },
      { text: "Entry amount", value: "entryAmount" },
    ] as Array<DataTableHeader>;
  }

  getSortBy() {
    return this.isBackTesting ? ["entryDate"] : ["createdAt"];
  }

  itemClass(item: Position) {
    if (item.id === this.activePosition?.id) {
      return "indigo lighten-5";
    }
  }
}
