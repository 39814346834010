
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Vue from "vue";

import { StrategyRun, StrategyRunSummary } from "api/models";
import { keyBy } from "lodash-es";
import AvatarIdenticon from "@/components/General/AvatarIdenticon.vue";
import ProfitPercent from "@/components/ProfitPercent.vue";
import { mdiCheck, mdiGamepadVariant, mdiMinus, mdiPlay, mdiStop } from "@mdi/js";

const index = {
  date: 0,
  netProfit: 1,
  hold: 2,
  lossMax0: 3,
};
@Component({
  components: { ProfitPercent, AvatarIdenticon },
})
export default class ProfitLoss extends Vue {
  @Prop({ required: true })
  strategyRun?: StrategyRun;

  @Prop({ required: true })
  summariesDaily?: Array<StrategyRunSummary>;

  @Prop({ required: true })
  summaryAll?: StrategyRunSummary;

  @Prop({ required: true })
  hodlChartData?: any;

  tab = 0;

  format1 = "0,0.[]";

  icons = {
    mdiCheck: mdiCheck,
    mdiMinus: mdiMinus,
    mdiPlay: mdiPlay,
    mdiStop: mdiStop,
    mdiGamepadVariant: mdiGamepadVariant,
  };

  created() {
    const summariesDailyIndexed = keyBy(this.summariesDaily, "periodName");

    const chartData = [];
    let netProfit = 0;
    let lossMax0 = 0;
    for (const [date, hodlValue] of Object.entries(this.hodlChartData)) {
      const summaryDaily = summariesDailyIndexed[date];
      if (summaryDaily) {
        netProfit += summaryDaily.netProfit || 0;
        lossMax0 += summaryDaily.netProfit || 0;
        if (lossMax0 > 0) {
          lossMax0 = 0;
        }
      }
      chartData.push([date, netProfit, hodlValue, lossMax0]);
    }

    // this.ei.dispatchAction({
    //   type: "pieSelect",
    //   name: "cat"
    // });
    this.options.dataset.source = chartData;
  }

  startBot() {
    this.$emit("start-bot");
  }

  stopBot() {
    this.$emit("stop-bot");
  }

  ei: any = {};

  options: any = {
    animation: false,
    dataset: {
      source: [],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false,
        },
      },
    },
    grid: [
      {
        top: 20,
        bottom: 50,
        left: "6%",
        right: "4%",
      },
    ],
    legend: {
      bottom: 0,
    },
    xAxis: [
      {
        type: "category",
        scale: true,
        boundaryGap: false,
        // inverse: true,
        axisLine: { onZero: false },
        splitLine: { show: false },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
      {
        type: "category",
        gridIndex: 0,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        splitNumber: 20,
        min: "dataMin",
        max: "dataMax",
      },
    ],
    yAxis: [
      {
        scale: true,
      },
    ],
    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0, 1],
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: "Profit",
        type: "line",
        symbol: "none",
        encode: {
          x: index.date,
          y: index.netProfit,
        },
        lineStyle: {
          width: 1,
          color: "#4ab1ff",
        },
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#eaf6ff",
            },
            {
              offset: 1,
              color: "#c0e5ff",
            },
          ],
          global: false, // false by default
        },
        areaStyle: {},
      },
      {
        name: "Hodl",
        type: "line",
        symbol: "none",
        encode: {
          x: index.date,
          y: index.hold,
        },
        lineStyle: {
          width: 1,
          color: "gray",
        },
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "gray",
            },
          ],
          global: false, // false by default
        },
      },
      {
        name: "Loss",
        type: "line",
        yAxisIndex: 0,
        symbol: "none",
        encode: {
          x: index.date,
          y: index.lossMax0,
        },
        lineStyle: {
          width: 1,
          color: "orange",
        },
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "yellow",
            },
            {
              offset: 1,
              color: "orange",
            },
          ],
          global: false, // false by default
        },
        areaStyle: {},
      },
    ],
  };
}
