
import Vue from "vue";
import Component from "vue-class-component";
import Avatars from "@dicebear/avatars";
import sprites from "@dicebear/avatars-identicon-sprites";
import { Prop } from "vue-property-decorator";

@Component
export default class AvatarIdenticon extends Vue {
  @Prop()
  name?: string;

  @Prop({ default: 50 })
  size?: number;

  @Prop({ default: 0 })
  margin?: number;

  getAvatar() {
    const avatars = new Avatars(sprites, {
      width: this.size,
      height: this.size,
      margin: this.margin,
    });
    return avatars.create(this.name);
  }
}
