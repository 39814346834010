
import Filters from "@/components/Filters.vue";
import BackTestChartGeneral from "@/components/BackTestChartGeneral.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    Filters,
    BackTestChartGeneral,
  },
})
export default class PositionEnvSummary extends Vue {
  @Prop({ required: true })
  positionEnvSummary: any;

  @Prop({ required: true })
  groups: any;

  @Prop({ required: true })
  candleChartData: any;

  @Prop({ required: true })
  filters: any;

  filteredPositionHistory: Array<any> = [];
  selectedPositionHistory: Array<any> = [];
  positionHistoryIndex = 0;
  sheet = false;

  @Watch("positionHistoryIndex")
  onPositionHistoryIndexChanged() {
    this.moveToPosition();
  }

  prev() {
    if (this.positionHistoryIndex > 0) {
      this.positionHistoryIndex--;
    }
  }
  next() {
    if (this.positionHistoryIndex < this.selectedPositionHistory.length - 1) {
      this.positionHistoryIndex++;
    }
  }
  moveToPosition() {
    const position = this.selectedPositionHistory[this.positionHistoryIndex] || null;
    this.filteredPositionHistory = [];
    if (position) {
      this.filteredPositionHistory.push(position);
      const from = position.entryBarIndex - 80;
      const to = position.exitBarIndex + 80;
      setTimeout(() => {
        this.$refs.charts.calculateData(from, to);
      });
    }
  }
  positionEnvSummaryForBuy() {
    return this.positionEnvSummary.filter((item: any) => item.action === "buy");
  }
  positionEnvSummaryForSell() {
    return this.positionEnvSummary.filter((item: any) => item.action === "sell");
  }
  selectGroup(item: any, positionSide: any) {
    this.sheet = true;
    this.selectedPositionHistory = item.positionHistory.filter(function (position: any) {
      return (
        (position.action === "buy" && positionSide === "buy") ||
        (position.action === "sell" && positionSide === "sell") ||
        positionSide === null
      );
    });
    this.positionHistoryIndex = 0;
    this.moveToPosition();
  }
}
