import { Position } from "next-trader-api";

const entryExitChartData = {
  generate(positionHistory?: Array<Position>) {
    if (!positionHistory) {
      positionHistory = [];
    }

    const entryData = [];
    const exitData = [];
    for (const position of positionHistory) {
      if (position.action === "buy") {
        entryData.push({
          name: "buy",
          coord: [position.entryBarIndex, position.entryPrice],
          value: position.barNumbersWhenOpen,
          itemStyle: {
            color: "green",
          },
        });
        if (position.exitBarIndex !== null) {
          exitData.push([
            {
              xAxis: position.exitBarIndex - 1,
              itemStyle: {
                color: position.profit > 0 ? "green" : "red",
              },
            },
            {
              xAxis: position.exitBarIndex,
            },
          ]);
        }
      }

      if (position.action === "sell") {
        entryData.push({
          name: "sell",
          coord: [position.entryBarIndex, position.entryPrice],
          value: position.barNumbersWhenOpen,
          itemStyle: {
            color: "red",
          },
          symbolRotate: 180,
          label: {
            offset: [0, 12],
          },
        });
        if (position.exitBarIndex !== null) {
          exitData.push([
            {
              xAxis: position.exitBarIndex - 1,
              itemStyle: {
                color: position.profit > 0 ? "green" : "red",
              },
            },
            {
              xAxis: position.exitBarIndex,
            },
          ]);
        }
      }
    }
    return {
      entryData: entryData,
      exitData: exitData,
    };
  },
};

export default entryExitChartData;
